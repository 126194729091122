<template>
  <div class="quv-cont">
    <div>
      <img src="@/assets/image/dxt.png" class="logo" alt="">


      <div class="btn-cont" >
        <div class="btn" @click="goPath('/quv/p1')" v-show="channel != 'dxthn2021001'">为自己验机</div>

        <div class="btn" @click="goPath('/quv/p2')">为他人验机</div>
      </div>
    </div>
    
  </div>
</template>

<script>
import { Devices} from '@icon-park/vue'

export default {
  components: {
    Devices
  },
  data () {
    return {
      type: this.$route.params.type ? this.$route.params.type : 1, // 1 事后， 2 事前
      query: this.$route.query,
      channel: this.$route.query.channel,

      isDcb: false,
    }
  },
  mounted () {
    
  },
  methods: {
    goPath (path) {
      this.$router.push({
        path: path,
        query: {
          type: this.type,
          ...this.query
        }
      })
    },
    
  },
}
</script>

<style lang="scss" scoped>
.quv-cont{
  min-height: 100.1vh;
  background-color: #0c945a;
  box-sizing: border-box;
  padding-bottom: 100px;
  padding-top: 10px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.form-sub{
  width: 410px;
  height: 100px;
  background: #1F955B;
  border-radius: 50px;
  text-align: center;
  font-size: 32px;
  line-height: 100px;
  color: #fff;
  margin: 0 auto;
  border: 4px solid #fff;
  box-shadow: 0px 4px 12px 6px rgba(0, 0, 0, 0.04);
  margin-top: 40px;
}
.logo{
  display: block;
  width: 340px;
  // height: 240px;
  margin: 0 auto;
  margin-bottom: 100px;
}
.logo2{
  display: block;
  height: 240px;
  margin: 0 auto;
  margin-bottom: 100px;
}
.uuid{
  font-size: 0.9em;
  text-align: center;
  color: #fff;
  // font-weight: 700;
}
.btn-cont{
  padding: 40px 10px;
}
.flex-cont{
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  font-size: 1.8em;
  padding: 40px 0 60px 0;
}

.btn{
  display: block;
  margin: 0 auto;
  width: 410px;
  height: 100px;
  line-height: 100px;
  border-radius: 100px;
  background: #fff;
  font-size: 32px;
  color: #1F955B;
  text-align: center;
  margin-bottom: 40px;
}
</style>
